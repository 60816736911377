import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BreadCrumb from '../components/breadCrumb'


const BlogPost = ({ data }) => {
  const { title, body } = data.contentfulProject
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const alt = node.data.target.fields.description['en-US']
        const url = node.data.target.fields.file['en-US'].url
        return <img alt={alt} src={url} />
      },
    },
  }

  const crumbs = [
    {
      depth: '1',
      url: '/',
      title: 'Home',
      styles: '',
    },
    {
      depth: '2',
      url: '/projects',
      title: 'Projects',
      styles: '',
    },
  ]

  return (
    <Layout>
      <SEO title={title} />
      <BreadCrumb crumbs={crumbs} />
      <section id="thoughtpost">
        <h1 className="text-4xl font-bold">{title}</h1>
        {documentToReactComponents(body.json, options)}
      </section>
    </Layout>
  )
}
export default BlogPost
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      body {
        json
      }
    }
  }
`
